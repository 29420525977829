import { IconButton, Grid, Box, SwipeableDrawer as Drawer, Container, makeStyles } from "@material-ui/core"
import { Menu as MenuIcon, Close as CloseIcon } from "@material-ui/icons"
import { useState } from "react"
import SocialShare from "../../../components/social-share"

const useStyles = makeStyles(() => ({
  social: {
    padding: 15,
    display: "inline-block",
    width: "auto",
    marginTop: -15,
    color: "#AD7D66",
    fontWeight: 600,
    textAlign: "left",
    marginBottom: 0,

    "& button": {
      color: "#fff!important",
      textAlign: "center!important",
      backgroundColor: "#AD7D66!important",
      "& > *": {
        textAlign: "center!important"
      }
    },

    "& *": {
      textAlign: "left",
      justifyContent: "flex-start"
    }
  }
}))

const MobileMenu = ({ menu }: { menu: any }) => {
  const [drawer, setDrawer] = useState(false)
  const classes = useStyles()

  const toggleDrawer = (val: any) => () => {
    setDrawer(val)
  }

  // Events.scrollEvent.register("begin", toggleDrawer(false))

  return (
    <Container>
      <Grid item xs={12}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Box height={40} display="flex" alignItems="center" pt={2}>
              <a href="/">
                <img alt="" src="/imgs/logo-black.svg" style={{ maxWidth: 120 }} />
              </a>
            </Box>
          </Grid>
          <IconButton
            style={{
              padding: 0,
              color: "#fff",
              height: 35,
              width: 35,
              fontSize: 30,
              borderRadius: 0,
              backgroundColor: "transparent",
              zIndex: 1000
            }}
            aria-label="Menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon style={{ color: "#323E48" }} />
          </IconButton>
        </Grid>
      </Grid>

      <Drawer className="drawer" anchor="right" open={drawer} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        <Grid container direction="column" style={{ height: "100%", display: "flex" }} spacing={0}>
          <Grid item style={{ flex: "0 1 auto" }}>
            <IconButton
              style={{
                color: "#323E48",
                height: 50,
                width: 50,
                margin: 0,
                borderRadius: 0,
                padding: 0,
                position: "absolute",
                zIndex: 1000,
                top: 5,
                right: 0
              }}
              aria-label="Menu"
              id="menubtn"
              onClick={toggleDrawer(false)}
            >
              <CloseIcon style={{ color: "#323E48" }} />
            </IconButton>
          </Grid>
          <Grid item style={{ flex: "1 1 auto" }}>
            <Box px={2} pb={4} tabIndex={0} style={{ outline: "none" }} role="button" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
              {menu}
            </Box>
          </Grid>
          <Grid item style={{ flex: "0 1 auto" }}>
            <SocialShare className={classes.social} title="Поделиться" />
          </Grid>
        </Grid>
      </Drawer>
    </Container>
  )
}

export default MobileMenu
