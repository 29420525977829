import { UseFormReturn } from "react-hook-form"
import { useEffect, useState } from "react"
import SnackbarCustom from "../snackbar"
import { SnackbarProp } from "../snackbar/snackbar"

type Props = {
  form: any
  text?: string
  onError?: any
}
const SnackbarAlert: React.FC<Props> = ({ form, onError = () => {}, text = "Внимание! Одно или несколько полей не заполнены или заполнены неверно. Проверьте корректность заполнения полей." }) => {
  const {
    formState: { isSubmitted, isSubmitting, isValid, errors }
  }: UseFormReturn = form

  const [snackbarProps, setSbnackbarProps] = useState<SnackbarProp>({
    show: false,
    onClose: () => {
      setSbnackbarProps({ ...snackbarProps, show: false })
    }
  })

  const snackbarError = (m: any) => {
    setSbnackbarProps({
      ...snackbarProps,
      answerMessage: m,
      show: true
    })
  }

  useEffect(() => {
    if (!isValid && Object.entries(errors).length > 0 && ((isSubmitting && !isSubmitted) || (isSubmitted && !isSubmitting))) {
      snackbarProps.onClose()
      setTimeout(() => {
        snackbarError({
          error: [text]
        })
      }, 200)
      onError(errors)
    } else {
      snackbarProps.onClose()
    }
  }, [errors, isSubmitted, isValid, isSubmitting])

  return <SnackbarCustom {...snackbarProps} />
}

export default SnackbarAlert
