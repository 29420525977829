import { Box, Button } from "@material-ui/core"
import Dialog from "../../components/dialog"
import russianRules from "../../utils/russianRules"

export type Msg = {
  status?: number
  title?: string
  message?: any
  btnText?: string | undefined
  text?: string | undefined
  onClose?: any
}

export type messageVariants = "alert" | "success" | "warning" | "error" | "info" | "reset"
export type Message = {
  [variant in messageVariants]?: string[]
}

const GlobalAlertModal: React.FC<{ msg?: Msg | undefined }> = ({ msg }) => {
  const { title = "Информация", message, text, btnText = "ОК", onClose = () => {} } = msg || {}

  const getText: any = (val: any) => {
    if (val === null) {
      return ""
    }
    if (Array.isArray(val)) {
      return val.map((v, _) => getText(v))
    }
    if (typeof val === "object") {
      return Object.entries(val).map(([_, v]) => getText(v))
    }
    if (typeof val === "string") {
      return <p className="alert_msg">{russianRules(val)}</p>
    }
    return ""
  }

  const getMsg = (ms: Message) => {
    return Object.entries(ms)
      .filter(([key]) => key !== "exception")
      .map(([k, val], idx) => {
        return <Box key={`alert-message-${idx}-${k}`}>{getText(val)}</Box>
      })
  }

  return (
    <Dialog title={title} maxWidth="sm" onOpen={() => {}} onCloseDialog={onClose} name="alert-modal" open={!!message}>
      <Box>
        {text && <Box>{russianRules(text)}</Box>}

        <Box>{!!message && getMsg(message)}</Box>
      </Box>
      <Box pt={4}>
        <Button fullWidth={false} type="submit" variant="contained" color="primary" onClick={() => onClose()}>
          {btnText}
        </Button>
      </Box>
    </Dialog>
  )
}

export default GlobalAlertModal
