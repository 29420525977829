import { middle_name, name, string_required, phone, email, address, validation, image } from "../../../../components/form-control/validation"

const validationShema = validation({
  last_name: name,
  first_name: name,
  middle_name,
  address,
  phone,
  email,
  pet_name: string_required,

  image_file: image
})

export default validationShema
