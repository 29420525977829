import { VKShareButton } from "react-share"
import { Box, Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVk } from "@fortawesome/free-brands-svg-icons"
import TrackEvent from "../GAnalitics"

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    borderRadius: 3,

    width: 32,
    lineHeight: "32px",
    backgroundColor: `#fff!important`,
    height: 32,
    userSelect: "none",
    color: `#AD7D66!important`,
    outline: "none",
    "& span": {
      width: "auto",
      fontSize: 20
    }
  },
  iconVK: {
    // backgroundColor: '#5181B8!important',
  },

  iconFB: {
    // backgroundColor: '#4267B2!important',
  },
  iconOK: {
    // backgroundColor: '#EE8208!important',
  },
  label: {
    textAlign: "left",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  }
}))

const getMetaContent = (function () {
  const metas: any = {}
  return (metaName: any) => {
    if (!metas[metaName]) {
      // eslint-disable-next-line consistent-return
      Array.prototype.some.call(document.getElementsByTagName("meta"), (el) => {
        if (el.name === metaName) {
          metas[metaName] = el.content
          return true
        }
        if (el.getAttribute("property") === metaName) {
          metas[metaName] = el.content
          return true
        }
        metas[metaName] = "meta tag not found"
      })
    }
    return metas[metaName]
  }
})()

const Url = getMetaContent("og:url")
const shareTitle = getMetaContent("og:title")
const shareDescription = getMetaContent("og:description")

function clickShareButton({ eventLabel }: { eventLabel: string }) {
  TrackEvent({ eventCategory: "ExternalReference", eventAction: "sharesn", eventLabel })
}

const SocialShare = ({ className, shareUrl = Url, img, title }: any) => {
  const classes = useStyles()
  //  const {title, description,image} = ;
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Grid container alignItems="center" className={className} alignContent="center" justify={xs ? "flex-start" : "center"} spacing={2}>
      {title && (
        <Grid item xs={12} className={classes.label}>
          {title}
        </Grid>
      )}
      <Grid item xs="auto" md="auto">
        <Grid container alignItems="center" wrap="nowrap" alignContent="center" justify="flex-start" spacing={1}>
          <Grid item>
            <VKShareButton
              beforeOnClick={() =>
                clickShareButton({
                  eventLabel: "vkontakte"
                })
              }
              url={shareUrl}
              title={`${shareTitle}. ${shareDescription}`}
              image={img}
              className={[classes.iconButton, classes.iconVK].join(" ")}
              color="primary"
            >
              <Box>
                <span className="fa-fw fa-1x">
                  <FontAwesomeIcon icon={faVk} size="1x" />
                </span>
              </Box>
            </VKShareButton>
          </Grid>
          {/* <Grid item> */}
          {/*  <FacebookShareButton */}
          {/*    beforeOnClick={() => */}
          {/*      clickShareButton({ */}
          {/*        eventLabel: "facebook" */}
          {/*      }) */}
          {/*    } */}
          {/*    url={shareUrl} */}
          {/*    className={[classes.iconButton, classes.iconFB].join(" ")} */}
          {/*    color="primary" */}
          {/*  > */}
          {/*    <Box> */}
          {/*      <span className="fa-fw fa-1x"> */}
          {/*        <FontAwesomeIcon icon={faFacebookF} size="1x" /> */}
          {/*      </span> */}
          {/*    </Box> */}
          {/*  </FacebookShareButton> */}
          {/* </Grid> */}
          {/* <Grid item> */}
          {/*  <OKShareButton */}
          {/*    beforeOnClick={() => */}
          {/*      clickShareButton({ */}
          {/*        eventLabel: "odnoklassniki" */}
          {/*      }) */}
          {/*    } */}
          {/*    url={shareUrl} */}
          {/*    title={shareTitle} */}
          {/*    image={img} */}
          {/*    description={shareDescription} */}
          {/*    className={[classes.iconButton, classes.iconOK].join(" ")} */}
          {/*    color="primary" */}
          {/*  > */}
          {/*    <Box> */}
          {/*      <span className="fa-fw fa-1x"> */}
          {/*        <FontAwesomeIcon icon={faOdnoklassniki} size="1x" /> */}
          {/*      </span> */}
          {/*    </Box> */}
          {/*  </OKShareButton> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SocialShare
