import { useForm } from "react-hook-form"
import { Box } from "@material-ui/core"
import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useHistory } from "react-router-dom"
import { parse } from "query-string"
import { yupResolver } from "@hookform/resolvers/yup"
import { forgotPasswordPhone } from "../../api/actions"
import { useSelector } from "../../hooks"
import { RegisterUser } from "../../types/user"
import PhoneInput from "../../components/form-control/phoneInput"
import SubmitButton from "../../components/submit-button"
import russianRules from "../../utils/russianRules"
import validationShema from "./validation"
import SnackbarAlert from "../../components/form-control/snackbarAlert"
import Answer from "../../utils/answer"

const ForgotForm = ({ setMsg }: { setMsg: any }) => {
  const history = useHistory()
  const [recaptchaRef, setRecaptchaRef] = useState<any>()
  const [formData, setFormData] = useState<any>(null)
  const [processed, setProcessed] = useState<boolean>(false)

  const {
    location: { search }
  } = history
  const { login } = parse(search)

  const { recaptchaSitekey } = useSelector((state) => state.propsReducer)

  const hookForm = useForm<any>({ defaultValues: { phone: login }, resolver: yupResolver(validationShema) })

  const onRecaptchaChange = (recaptchaToken: string | null) => {
    forgotPasswordPhone({
      recaptcha: recaptchaToken,
      ...formData
    })
      .then((res) => {
        const { status, message: messages } = res || {}
        setProcessed(false)
        if (status === 1) {
          setMsg({
            status,
            message: res.message,
            onClose: () => {
              setMsg(undefined)

              history.push({
                pathname: "/",
                search: `?signin&login=${formData.phone}`
              })
            }
          })
        } else {
          setMsg({
            status: 0,
            message: Answer(status !== undefined && messages !== undefined ? messages : { error: ["Сервер временно не доступен. Пожалуйста, попробуйте позже"] }),
            onClose: () => {
              setMsg(undefined)
            }
          })
        }
      })
      .catch(() => {
        setMsg({
          status: 0,
          message: Answer({ error: ["Сервер временно не доступен. Пожалуйста, попробуйте позже"] }),
          onClose: () => {
            setMsg(undefined)
          }
        })
      })
      .finally(() => {
        recaptchaRef?.reset()
        setProcessed(false)
      })
  }

  const onSubmit = (props: RegisterUser | any) => {
    setFormData(props)
    setProcessed(true)
    recaptchaRef?.execute()
  }

  const onRecaptchaExpired = () => {
    setProcessed(false)
  }

  return (
    <>
      <form onSubmit={hookForm.handleSubmit(onSubmit)} noValidate>
        {recaptchaSitekey && <ReCAPTCHA ref={(ref: any) => setRecaptchaRef(ref)} size="invisible" sitekey={recaptchaSitekey} onExpired={onRecaptchaExpired} onChange={onRecaptchaChange} />}

        <Box maxWidth={480} pt={0} style={{ margin: "0 auto" }}>
          <Box className="text" pb={5}>
            {russianRules("Введите номер телефона, указанный при регистрации. Пароль к Вашему Личному кабинету будет отправлен на указанный номер телефона.")}
          </Box>
          <Box>
            <PhoneInput name="phone" form={hookForm} placeholder="+7 (999) 000 00 00" />
          </Box>
          <Box>
            <SnackbarAlert form={hookForm} />
          </Box>
          <Box pt={2}>
            <SubmitButton color="secondary" variant="contained" fullWidth disabled={processed} processed={processed} title="Восстановить" />
          </Box>
        </Box>
      </form>
    </>
  )
}

export default ForgotForm
