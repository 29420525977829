import { Link } from "react-router-dom"
import { Link as LinkHttp } from "@material-ui/core"
import React from "react"
import TrackEvent from "../GAnalitics"

type Props = {
  href?: string
  to?: any
  eventCategory?: string
  eventAction?: string
  eventLabel?: string
  color?: string
  className?: any
  target?: any
  download?: any
}

const onClick = ({ href, to, eventCategory, eventAction, eventLabel }: Props) => {
  if (href) {
    TrackEvent({
      eventCategory: eventCategory || "ExternalReference",
      eventAction: "click_url",
      eventContext: href
    })
  }

  if (to) {
    TrackEvent({
      eventCategory: eventCategory || "Link",
      eventAction: eventAction || "button_click",
      eventLabel,
      eventContext: to
    })
  }
}

const CustomLink: React.FC<Props> = ({ eventCategory, target, eventLabel, eventAction, children, href, to, className, ...props }) => {
  if (href) {
    return (
      <LinkHttp href={href} target={target} color="primary" component="a" onClick={() => onClick({ href })} className={className}>
        {children}
      </LinkHttp>
    )
  }

  return <Link to={to} {...props} />
  // onClick={() => onClick({ eventCategory, eventAction, eventLabel, to })}
}

export default CustomLink
