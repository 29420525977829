import { Box, Grid, Typography, Button, useMediaQuery, Theme } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { User } from "../../../types/user"
import { SET_PROPS } from "../../../store/props/types"
import { modalName as ExhibitionModalName } from "../modal/exhibition"
import ExhibitionList from "./exhibition-list"
import russianRules from "../../../utils/russianRules"
import useStyles from "./style"

const Exhibition: React.FC<{ user: User }> = ({ user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"))
  const { breeder_anketa_exhibition } = user || {}

  return (
    <Grid container justify="flex-start" className="animated fadeIn" spacing={4}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" color="secondary">
          Выставки
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box py={5} className={`${classes.root} ${classes.mFullWidth}`}>
          <Box pb={2} px={2}>
            <Typography variant="h5">{russianRules("Получите бонус за добавление информации о полученном титуле на выставке")}</Typography>
          </Box>
          <Box py={2} px={2}>
            <Button
              fullWidth={sm}
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch({
                  type: SET_PROPS,
                  payload: { modal: { [ExhibitionModalName]: { show: true } } }
                })
              }}
            >
              Добавить выставку
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mb={4} className={classes.mFullWidth}>
          <ExhibitionList items={breeder_anketa_exhibition} classes={classes} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Exhibition
