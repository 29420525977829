import { Box, Grid, useMediaQuery, useTheme, Zoom, Fade } from "@material-ui/core"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"
import NumberFormat from "react-number-format"
import { useEffect, useState } from "react"
import { Level, User } from "../../../types/user"
import { get } from "../../../api/actions"
import russianRules from "../../../utils/russianRules"
import useStyles from "./offspring.style"
import PackageInfo from "../../../components/packageInfo"

const OffspringPackage: React.FC<{ user: User; loadInfo: any }> = ({ user }) => {
  const classes = useStyles()
  const { breeder_level_current } = user || {}
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const [levels, setLevels] = useState<Level[]>()

  useEffect(() => {
    get("/user/breeder/level_parameter", {}).then((res) => {
      setLevels(res?.data)
    })
  }, [])

  return (
    <Grid container alignItems="stretch" spacing={md ? 0 : 1} style={{ height: "100%" }}>
      <Grid item xs={12}>
        <Grid container style={{ height: "100%" }} alignItems="center" className={classNames(classes.box3, "animated faster zoomIn")}>
          <Grid item xs={12}>
            <Box p={1}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="title">{breeder_level_current.title}</Box>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify="space-between" wrap="nowrap" alignItems="center" spacing={2}>
                    <Grid item xs="auto">
                      <Box className="text">{russianRules("В среднем в месяц вы покупаете&nbsp;на")}</Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box className="ball">
                        <NumberFormat displayType="text" thousandSeparator="&nbsp;" value={breeder_level_current?.pivot?.summa ?? 0} suffix="&nbsp;&#8381;" />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={4}>
                      <Fade in={typeof levels !== "undefined"} timeout={200} appear>
                        <Box className="hr p1" />
                      </Fade>
                    </Grid>
                    <Grid item xs={4}>
                      <Fade in={typeof levels !== "undefined"} timeout={400} appear>
                        <Box className="hr p2" />
                      </Fade>
                    </Grid>
                    <Grid item xs={4}>
                      <Fade in={typeof levels !== "undefined"} timeout={600} appear>
                        <Box className="hr p3" />
                      </Fade>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify="space-between" alignItems="center">
                    {levels?.map((i: Level, idx: number) => {
                      return (
                        <Zoom in timeout={idx * 200} key={`pack-${i.id}`}>
                          <Grid item xs={4}>
                            <Box className={classNames("t", breeder_level_current.key === i.breeder_level?.key ? "b" : "")}>
                              <PackageInfo level={i}>
                                от
                                <NumberFormat prefix="&nbsp;" displayType="text" thousandSeparator="&nbsp;" value={i?.min ?? 0} suffix="&nbsp;&#8381;" />
                              </PackageInfo>
                            </Box>
                          </Grid>
                        </Zoom>
                      )
                    }) ?? (
                      <>
                        <Grid item xs={4}>
                          <Box className="t" style={{ opacity: 0 }}>
                            ...
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box className="t" style={{ opacity: 0 }}>
                            ...
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box className="t" style={{ opacity: 0 }}>
                            ...
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OffspringPackage
