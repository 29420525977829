import { Box, Grid, makeStyles, Container, useTheme, useMediaQuery } from "@material-ui/core"
import Moment from "react-moment"
import CustomLink from "../../../components/link/custom-link"
import SocialShare from "../../../components/social-share"
import { useSelector } from "../../../hooks"

const useStyles = makeStyles((theme) => ({
  footerBody2: {
    paddingTop: 15,
    color: "#fff",
    paddingBottom: 15,
    "& a": {
      color: "#fff",
      textDecoration: "none"
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 12
    }
  },
  footerBody: {
    paddingTop: 30,
    paddingBottom: 30,
    color: "#fff",
    fontSize: 16,
    fontWeight: 500,
    borderBottom: "1px solid #fff",
    "& a": {
      color: "#fff",
      textDecoration: "none"
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 14
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      "& img.logo": {
        margin: "0 auto",
        position: "relative",
        display: "block",
        marginBottom: 30
      }
    }
  },
  menuItem: {
    color: "#fff",
    borderBottom: "1.5px dotted #fff",
    "&:hover": {
      textDecoration: "none"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      fontWeight: 600
    }
  }
}))

const Footer = () => {
  const classes = useStyles()
  const { isAuth, userStatus, doc } = useSelector((state) => state.propsReducer)

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <footer>
      <Box bgcolor="#AD7D66">
        <Container maxWidth={false}>
          <Grid container className={classes.footerBody} spacing={0} justify="center">
            <Grid item xs={12} sm={12} md={3} lg={5}>
              <img width={135} className="logo" src="/imgs/logo-bottom.svg" alt="" />
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={7}>
              <Grid container spacing={3} justify="center" style={{ textAlign: !xs ? "center" : "left" }}>
                <Grid item xs="auto" md={5} lg={4}>
                  Задать вопрос
                  <br />
                  о&nbsp;программе лояльности:
                  <br />
                  <Box pt={2} pb={{ xs: 2, sm: 0 }} style={{ whiteSpace: "nowrap" }}>
                    <CustomLink color="secondary" target="_blank" href="mailto:breeder@alphapet-info.ru">
                      <img src="/imgs/mail.svg" style={{ marginRight: 10, marginBottom: -10 }} alt="" />
                      breeder@alphapet-info.ru
                    </CustomLink>
                  </Box>
                </Grid>

                <Grid item xs="auto" md={4} lg={4}>
                  Получить консультацию
                  <br /> о&nbsp;продукции ТМ&nbsp;AlphaPet<sup>®</sup>:
                  <br />
                  <Box pt={2} pb={{ xs: 2, sm: 0 }} style={{ whiteSpace: "nowrap" }}>
                    <CustomLink color="secondary" target="_blank" href="mailto:info@alphapet.ru">
                      <img src="/imgs/mail.svg" style={{ marginRight: 10, marginBottom: -10 }} alt="" />
                      info@alphapet.ru
                    </CustomLink>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <Box>
                    <SocialShare title="Поделиться" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.footerBody2} spacing={2}>
            <Grid item xs={12} sm={5} md={5} lg={5}>
              © 2009-
              <Moment format="yy" /> AlphaPet<sup>®</sup>
            </Grid>

            <Grid item xs={12} sm={7} md={7} lg={7} style={{ order: xs ? 0 : -1 }}>
              <Grid container justify="flex-end" spacing={4} alignItems="center">
                {doc?.promotion_rules && (
                  <Grid item xs={12} sm="auto" md="auto">
                    <CustomLink color="secondary" className={classes.menuItem} href={doc.promotion_rules.primary_src} target="_blank">
                      Правила акции
                    </CustomLink>
                  </Grid>
                )}

                {isAuth && userStatus !== "new" && doc?.rules && (
                  <Grid item xs={12} sm="auto" md="auto">
                    <CustomLink color="secondary" className={classes.menuItem} href={doc.rules.primary_src} target="_blank">
                      Правила программы лояльности
                    </CustomLink>
                  </Grid>
                )}

                {doc?.agreement && (
                  <Grid item xs={12} sm="auto" md="auto">
                    <CustomLink color="secondary" className={classes.menuItem} href={doc.agreement.primary_src} target="_blank">
                      Пользовательское соглашение
                    </CustomLink>
                  </Grid>
                )}

                <Grid item xs={12} sm="auto" md="auto">
                  <CustomLink color="secondary" href="https://atoms.ru" target="_blank" className={classes.menuItem}>
                    Программа лояльности разработана в
                  </CustomLink>
                  <CustomLink color="secondary" href="https://atoms.ru" target="_blank">
                    <img style={{ height: 26, display: "inline-block", marginLeft: 10, marginBottom: -11 }} alt="Atoms" src="/imgs/logo_white.png" />
                  </CustomLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  )
}

export default Footer
