import { fetchPrivateAPIToken, withPrivateAPIToken, withPublicAPIToken, signout as signoutService, apiFetchData } from "./service"

const { REACT_APP_DADATA_TOKEN } = process.env

export const lkInfo = async () => {
  return withPrivateAPIToken({ url: "/lk/get", method: "get" })
}

export const constants = async () => {
  return withPublicAPIToken({ url: "/constant", method: "get" })
}
export const auth = async () => {
  return withPrivateAPIToken({ url: "/user/breeder/info", method: "get" })
}

export const signin = async (username: string, password: string) => {
  return fetchPrivateAPIToken(username, password)
}

export const signout = async () => {
  return signoutService()
}

export const checkUniqEmail = (props: any) => {
  return withPublicAPIToken({
    url: "/user/exists",
    method: "post",
    data: props
  })
}
export const checkUniqEmailAuth = (props: any) => {
  return withPrivateAPIToken({
    url: "/user/exists/auth",
    method: "post",
    data: props
  })
}

export const checkUniqPhone = (props: any) => {
  return withPublicAPIToken({
    url: "/user/exists",
    method: "post",
    data: props
  })
}

export const signup = (props: any) => {
  // const invite = ls.get("invite");
  return withPublicAPIToken({
    url: "/user/breeder/register",
    method: "post",
    data: props
  })
}

export const changePassword = async (data: any) => {
  return withPrivateAPIToken({
    url: "/user/breeder/password",
    method: "post",
    data
  })
}
export const forgotPasswordPhone = async (data: any) => {
  return withPublicAPIToken({
    url: "/user/breeder/forgot/password/phone",
    method: "post",
    data
  })
}

export const subscribeChange = async (props: any) => {
  return withPrivateAPIToken({
    url: "/user/subscribe/change",
    method: "post",
    data: props
  })
}

export const updateUser = async (data: any) => {
  return withPrivateAPIToken({
    url: `/user/update`,
    method: "post",
    data
  })
}

export const post = async (url: string, data: any) => {
  return withPrivateAPIToken({
    url,
    method: "post",
    data
  })
}
export const postPublic = async (url: string, data: any) => {
  return withPublicAPIToken({
    url,
    method: "post",
    data
  })
}

export const get = async (url: string, data: any) => {
  return withPrivateAPIToken({
    url,
    method: "get",
    params: data
  })
}

export const getPublick = async (url: string, data: any) => {
  return withPublicAPIToken({
    url,
    method: "get",
    data
  })
}

export const getFias = async (data: { query: string }): Promise<any> =>
  apiFetchData({
    url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    headers: { Authorization: `Token ${REACT_APP_DADATA_TOKEN}` },
    method: "post",
    data: JSON.stringify({
      ...data,
      lang: "ru",
      bounds: "city-settlement",
      locations: [
        {
          country_iso_code: "RU"
        }
      ]
    })
  })

export const getFiasFull = async (data: { query: string }): Promise<any> =>
  apiFetchData({
    url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    headers: { Authorization: `Token ${REACT_APP_DADATA_TOKEN}` },
    method: "post",
    data: JSON.stringify({
      ...data,
      lang: "ru",
      locations: [
        {
          country_iso_code: "RU"
        }
      ]
    })
  })
