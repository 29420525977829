import { useForm } from "react-hook-form"
import { Box, Link } from "@material-ui/core"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { parse } from "query-string"
import { yupResolver } from "@hookform/resolvers/yup"
import { signin } from "../../api/actions"
import TrackEvent from "../../components/GAnalitics"
import { SnackbarProp } from "../../components/snackbar/snackbar"
import AuthAction from "../../store/auth/action"
import { SET_PROPS } from "../../store/props/types"
import PhoneInput from "../../components/form-control/phoneInput"
import PasswordInput from "../../components/form-control/passwordInput"
import SocialAuth from "../../components/social-auth"
import MyLink from "../../page/layout/menu/myLink"
import SubmitButton from "../../components/submit-button"
import validationShema from "./validation"
import useStyles from "./style"
import SnackbarAlert from "../../components/form-control/snackbarAlert"
import Answer from "../../utils/answer"

const SigninForm: React.FC<{ setMsg: any }> = ({ setMsg }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    location: { search }
  } = history
  const { login } = parse(search)

  const [snackbarProps, setSbnackbarProps] = useState<SnackbarProp>({
    show: false,
    onClose: () => {
      setSbnackbarProps({ ...snackbarProps, show: false })
    }
  })
  const [processed, setProcessed] = useState(false)

  const hookForm = useForm<any>({
    defaultValues: { username: login },
    resolver: yupResolver(validationShema)
  })

  const handlerForgot = (e: any) => {
    e.preventDefault()
    history.push({
      pathname: "/",
      search: `?forgot${hookForm.getValues("username") ? `&login=${hookForm.getValues("username")}` : ""}`
    })
  }

  const submitFailed = (err: any) => {
    // snackbarProps.onClose()
    TrackEvent({
      eventCategory: "Registration",
      eventAction: "send_r_form",
      eventLabel: "unsuccess",
      eventContext: `Некоторые поля не заполнены или заполнены неверно: ${Object.entries(err)
        .map(([key]) => key)
        .join(", ")}`
    })
  }

  const SuccesAuth = () => {
    dispatch({
      type: SET_PROPS,
      payload: { isAuth: true }
    })
    setTimeout(() => {
      history.push("/profile")
      setProcessed(false)
    }, 1000)
  }

  const ErrorAuth = () => {
    dispatch({
      type: SET_PROPS,
      payload: { isAuth: false }
    })
    setProcessed(false)
  }

  const onSubmit = ({ username, password }: { username: string; password: string } | any) => {
    setProcessed(true)
    snackbarProps.onClose()
    signin(username, password.replace(/\s/g, ""))
      .then((r) => {
        if (r !== null && r.status === 1) {
          dispatch(AuthAction(SuccesAuth, ErrorAuth))
          TrackEvent({ eventCategory: "Authorization", eventAction: "send_a_form", eventLabel: "success" })
        } else {
          setProcessed(false)
          setMsg({
            status: 0,
            message: Answer(r.message),
            onClose: () => {
              setMsg(undefined)
            }
          })

          TrackEvent({
            eventCategory: "Authorization",
            eventAction: "send_a_form",
            eventLabel: "unsuccess",
            eventContext: "Неверный логин или пароль"
          })
        }
      })
      .catch(() => {
        setProcessed(false)
        setMsg({
          status: 0,
          message: Answer({ error: ["Неверный логин или пароль."] }),
          onClose: () => {
            setMsg(undefined)
          }
        })
      })
      .finally(() => {})
  }

  return (
    <>
      <Box pb={1} maxWidth={480} style={{ margin: "0 auto", borderBottom: "1px solid #DAD0CB", marginBottom: 40 }}>
        <SocialAuth userProvider={[]} eventCategory="Authorization" eventAction="over_social_a" label="Войти через соц.сеть" classNameLabel={classes.socialLabel} />
      </Box>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} autoComplete="off" noValidate>
        <Box maxWidth={480} pt={5} style={{ margin: "0 auto" }}>
          <Box>
            <PhoneInput name="username" form={hookForm} placeholder="+7 (999) 000 00 00" />
          </Box>
          <Box>
            <PasswordInput name="password" form={hookForm} />
          </Box>
          <Box flex="1 1 auto" textAlign="right" style={{ marginTop: -20 }}>
            <a href="/#" className={classes.link} onClick={handlerForgot}>
              Забыли пароль?
            </a>
          </Box>

          <Box pt={2}>
            <SnackbarAlert form={hookForm} />
          </Box>

          <Box pt={2} pb={2}>
            <SubmitButton variant="contained" color="primary" type="submit" processed={processed}>
              Вход
            </SubmitButton>
          </Box>

          <Box pb={2}>
            <Link className={classes.link} style={{ fontSize: "90%" }} to="/?signup" component={MyLink}>
              Зарегистрироваться
            </Link>
          </Box>
        </Box>
      </form>
    </>
  )
}

export default SigninForm
