import { useForm, useWatch } from "react-hook-form"
import { Box } from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { changePassword } from "../../api/actions"

import SubmitButton from "../../components/submit-button"
import PasswordInput from "../../components/form-control/passwordInput"
import validationShema from "./validation"
import SnackbarAlert from "../../components/form-control/snackbarAlert"
import Answer from "../../utils/answer"

const ChangePasswordForm = ({ onClose, setMsg }: { onClose: any; setMsg: any }) => {
  const [processed, setProcessed] = useState<boolean>(false)

  const hookForm = useForm<any>({
    resolver: yupResolver(validationShema)
  })
  const { getValues, setError, control, clearErrors } = hookForm
  const password_confirmation = useWatch({ control, name: "password_confirmation" })
  const password = useWatch({ control, name: "password" })

  const validateCustom = () => {
    if (getValues("password_confirmation").length >= 6 && getValues("password") !== getValues("password_confirmation")) {
      setError("password_confirmation", { type: "custom", message: "Пароли не совпадают" })
      return false
    }

    if (getValues("password_confirmation").length >= 6) {
      clearErrors("password_confirmation")
    }

    return true
  }

  useEffect(() => {
    validateCustom()
  }, [password_confirmation, password])

  const submit = useCallback((props: any) => {
    if (!validateCustom()) {
      setMsg({
        status: 0,
        message: Answer({ error: ["Введенные пароли не совпадают."] }),
        onClose: () => {
          setMsg(undefined)
        }
      })
    } else {
      setProcessed(true)
      changePassword({
        ...props
      })
        .then((res) => {
          const { status = 0, message: messages } = res || {}
          setProcessed(false)
          if (status === 1) {
            setMsg({
              status,
              message: messages,
              onClose: () => {
                onClose()
                setMsg(undefined)
              }
            })
          } else {
            setMsg({
              status: 0,
              message: Answer(status !== undefined && messages !== undefined ? messages : { error: ["Сервер временно не доступен. Пожалуйста, попробуйте позже"] }),
              onClose: () => {
                setMsg(undefined)
              }
            })
          }
        })
        .catch(() => {
          setMsg({
            status: 0,
            message: Answer({ error: ["Сервер временно не доступен. Пожалуйста, попробуйте позже"] }),
            onClose: () => {
              setMsg(undefined)
            }
          })
        })
        .finally(() => {
          setProcessed(false)
        })
    }
  }, [])

  return (
    <>
      <form onSubmit={hookForm.handleSubmit(submit)} noValidate>
        <Box maxWidth={390} pt={0} style={{ margin: "10px auto" }}>
          <Box>
            <PasswordInput label="Введите новый пароль" name="password" form={hookForm} />
          </Box>

          <Box>
            <PasswordInput password={password} label="Повторите пароль" name="password_confirmation" form={hookForm} />
          </Box>
          <Box>
            <SnackbarAlert form={hookForm} />
          </Box>
          <Box pt={2}>
            <SubmitButton color="secondary" variant="contained" fullWidth disabled={processed} processed={processed} title="Сменить пароль" />
          </Box>
        </Box>
      </form>
    </>
  )
}

export default ChangePasswordForm
