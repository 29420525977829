import { Box, Popover, Grid, GridJustification } from "@material-ui/core"

import { useEffect, useState } from "react"
import NumberFormat from "react-number-format"
import { Level } from "../../types/user"

type Prop = {
  anchorOrigin?: any
  transformOrigin?: any
  level?: Level
  justify?: GridJustification
}

const PackageInfo: React.FC<Prop> = ({ anchorOrigin = {}, transformOrigin = {}, children, level, justify = "center" }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [limit, setLimit] = useState(0)

  const { breeder_level } = level || {}

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    breeder_level?.breeder_gift_parameter
      .filter((gift) => gift.breeder_gift.key === "fodder")
      // eslint-disable-next-line array-callback-return
      .map((i) => {
        setLimit(i.limit_period)
      })
  }, [level])

  return (
    <Grid container spacing={1} justify={justify} wrap="nowrap" alignItems="flex-start">
      <Grid item xs={12}>
        <Box style={{ cursor: "pointer" }} aria-owns={open ? "mouse-over-popover" : undefined} aria-haspopup="true" onClick={handlePopoverOpen} tabIndex={-1}>
          {children}
        </Box>

        <Popover
          style={{ maxWidth: 300, textTransform: "uppercase", fontWeight: 600 }}
          disableRestoreFocus
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
            ...anchorOrigin
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
            ...transformOrigin
          }}
        >
          <Box p={2}>
            <Box pb={1} color="#AD7D66">
              {breeder_level?.title}
            </Box>
            <Box pt={1} style={{ fontWeight: 400, fontSize: 14, textTransform: "none" }}>
              Сумма покупок в месяц от <NumberFormat prefix="&nbsp;" displayType="text" thousandSeparator="&nbsp;" value={level?.min ?? 0} suffix="&nbsp;&#8381;" />{" "}
              {level?.max !== 0 && (
                <>
                  до <NumberFormat prefix="&nbsp;" displayType="text" thousandSeparator="&nbsp;" value={level?.max ?? 0} suffix="&nbsp;&#8381;" />
                </>
              )}
              <br />
              Количество выкорма в год – {limit} шт.
            </Box>
          </Box>
        </Popover>
      </Grid>
    </Grid>
  )
}

export default PackageInfo
