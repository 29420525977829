import { useEffect, useState } from "react"
import { Box, Table, TableRow, TableCell, TableHead, TableBody, Grid, Hidden } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import Moment from "react-moment"
import { Advertising } from "../../../types/user"
import Status from "../../../components/status"
import BallView from "../../../components/ball/ball"

const countArray = (ar: any) => (ar ? ar.length : 0)

const AdvertisingList: React.FC<{ items: Advertising[] }> = ({ items = [] }) => {
  const [page, setPage] = useState<number>(1)
  const perPage = 5

  const handleChange = (_event: any, value: number) => {
    setPage(value)
  }
  const [exhib, setExhib] = useState<Advertising[]>([])

  useEffect(() => {
    if (items) {
      setExhib([...items].reverse())
    }
  }, [items])

  return (
    <>
      <Table key="AdvertisingList">
        <TableHead>
          <TableRow>
            <Hidden xsDown>
              <TableCell>ИСТОЧНИК</TableCell>
              <TableCell>СТАТУС</TableCell>
              <TableCell>ДАТА НАЧИСЛЕНИЯ БАЛЛОВ</TableCell>
              <TableCell>НАЧИСЛЕННЫЕ БАЛЛЫ</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {exhib.length > 0 ? (
            exhib.slice(perPage * (page - 1), page * perPage).map((advertising: Advertising) => {
              const { id, href, breeder_balance, breeder_anketa_social_status: status, breeder_anketa_social_status_comment: status_comment } = advertising

              return (
                <TableRow key={`advertising-${id}`}>
                  <Hidden xsDown>
                    <TableCell>
                      <Box className="linktext" title={href}>
                        {href?.replace(/http?s:\/\//, "").replace(/\/.+/, "")}
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Status comment={status_comment}>{status.title}</Status>
                    </TableCell>

                    <TableCell>{breeder_balance?.created_at ? <Moment format="DD.MM.yyyy">{breeder_balance?.created_at}</Moment> : "-"}</TableCell>

                    <TableCell>
                      <BallView>{breeder_balance?.score}</BallView>
                    </TableCell>
                  </Hidden>

                  <Hidden smUp>
                    <TableCell>
                      <Box textAlign="left" fontSize={14}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid item className="m_cell_title">
                                Статус
                              </Grid>
                              <Grid item xs={12} className="m_cell">
                                <Status justify="flex-start" comment={status_comment}>
                                  {status.title}
                                </Status>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Box fontSize={18}>
                              <BallView style={{ justifyContent: "flex-end" }}>{breeder_balance?.score}</BallView>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item className="m_cell_title">
                                Дата начисления баллов
                              </Grid>
                              <Grid item xs={12} className="m_cell">
                                {breeder_balance?.created_at ? <Moment format="DD.MM.yyyy">{breeder_balance?.created_at}</Moment> : "-"}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item className="m_cell_title">
                                Источник
                              </Grid>
                              <Grid item xs={12} className="m_cell">
                                <Box className="linktext" title={href}>
                                  {href?.replace(/http?s:\/\//, "").replace(/\/.+/, "")}
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </TableCell>
                  </Hidden>
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={4}>Здесь будет информация о ссылках</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {countArray(items) > perPage && (
        <Box pt={4} textAlign="center">
          <Pagination page={page} variant="text" onChange={handleChange} count={Math.ceil(countArray(items) / perPage)} />
        </Box>
      )}
    </>
  )
}

export default AdvertisingList
