import { useForm } from "react-hook-form"
import { Box } from "@material-ui/core"
import { useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import SnackbarAlert from "../../../../components/form-control/snackbarAlert"
import { get, post } from "../../../../api/actions"
import SubmitButton from "../../../../components/submit-button"
import NumberInput from "../../../../components/form-control/numberInput"
import { Entity } from "../../../../types/user"
import { ApiAnswerStatus } from "../../../../api/types"
import DateInput from "../../../../components/form-control/dateInput"
import Answer from "../../../../utils/answer"
import validationShema from "./validation"
import { AutocompleteSelect, Option } from "../../../../components/form-control/autocomplete-select"
import DropzoneInput from "../../../../components/form-control/dropzoneInput"

const Form = ({ onClose = () => {}, setMsg = () => {} }: { onClose: any; setMsg: any }) => {
  const [processed, setProcessed] = useState<boolean>(false)
  const [species, setSpecies] = useState<Entity[]>()
  const [breed, setBreed] = useState<Entity[]>([])

  const hookForm = useForm<any>({
    resolver: yupResolver(validationShema)
  })
  const { setValue } = hookForm

  useEffect(() => {
    get("/data/breed_species/get", {}).then((res) => {
      if (res.status === ApiAnswerStatus.SUCCESS) setSpecies(res.data)
    })
  }, [])

  const loadBreed = (e: Option) => {
    setValue("breed_id", undefined)
    setBreed([])
    const breeds: Entity[] = []
    if (e) {
      get("/data/breed/get", { breed_species_id: e.id || 0 }).then((res) => {
        if (res.status === ApiAnswerStatus.SUCCESS) {
          for (let i = 0; i < res.data.length; i++) {
            breeds.push({ id: res.data[i].id, title: res.data[i].title, key: `key${res.data[i].id}` })
          }
          setBreed(breeds)
        }
      })
    }
  }

  const onSubmit = (props: any) => {
    setProcessed(true)

    const formData = new FormData()

    // eslint-disable-next-line array-callback-return
    Object.keys(props).map((k: string) => {
      if (k === "image_file") {
        for (let i = 0; i < props[k].length; i++) {
          formData.append("image[]", props[k][i])
        }
      } else if (k === "birthday_at") {
        formData.append("birthday_at", moment(props[k]).format("yyyy-MM-DD"))
      } else {
        formData.append(k, typeof props[k] === "object" ? props[k].id || props[k] : props[k])
      }
    })

    post(`/user/breeder/anketa_offspring`, formData)
      .then((res) => {
        setMsg({
          status: res.status,
          message: Answer(res.message),
          onClose: () => {
            setMsg(undefined)
            if (res.status === 1) onClose()
          }
        })
      })
      .catch(() => {})
      .finally(() => {
        setProcessed(false)
      })
  }

  if (!species) {
    return <></>
  }

  return (
    <>
      <form onSubmit={hookForm.handleSubmit(onSubmit)} noValidate>
        <Box maxWidth={480} pt={2} style={{ margin: "0 auto" }}>
          <Box>
            <AutocompleteSelect placeholder="Не выбрано" label="Питомец" form={hookForm} name="breed_species_id" defaultOptions={species} onchange={loadBreed} />
          </Box>

          <Box>
            <AutocompleteSelect placeholder="Не выбрано" label="Порода" form={hookForm} name="breed_id" defaultOptions={breed} disabled={breed.length === 0} />
          </Box>

          <Box>
            <DateInput label="Дата рождения помёта" name="birthday_at" form={hookForm} />
          </Box>

          <Box>
            <NumberInput name="offspring_count" label="Количество щенков/котят в помёте" form={hookForm} placeholder="Пример: 6" />
          </Box>

          <Box>
            <DropzoneInput label="Фото акта обследования помёта (jpg,png,pdf)" form={hookForm} name="image_file" />
          </Box>

          <Box>
            <SnackbarAlert form={hookForm} />
          </Box>
        </Box>

        <Box pt={2}>
          <SubmitButton color="secondary" variant="contained" disabled={processed} processed={processed} title="Добавить" />
        </Box>
      </form>
    </>
  )
}

export default Form
