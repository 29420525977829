import { ReactNode } from "react"
import { Autocomplete } from "@material-ui/lab"
import { UseFormReturn, Controller } from "react-hook-form"
import { Box, TextField } from "@material-ui/core"
import { withPlanner, WithPlannerProps } from "../hoc"

type Props = {
  form: UseFormReturn<any>
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  processed?: boolean
  optionIcon?: ReactNode
  noOptionsText?: string
  defaultOptions: Option[]
  onchange?: any
}

export type Option = {
  id: number
  title: string
  key: string
}

export const AutocompleteSelect: React.FC<Props & WithPlannerProps> = withPlanner((props): JSX.Element => {
  const { form, name, label, placeholder, defaultOptions, onchange = () => {}, disabled } = props

  const { control } = form

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          disabled={disabled}
          disableListWrap
          disabledItemsFocusable
          getOptionLabel={(option) => option.title}
          id={`${name}-id`}
          onChange={(_event, value) => {
            field.onChange(value)
            onchange(value)
          }}
          getOptionSelected={(option, value) => {
            return option.id === value.id
          }}
          filterOptions={(options, _state) => {
            const newOptions: Option[] = []
            options.forEach((element) => {
              if (element.title.toLowerCase().includes(_state.inputValue.toLowerCase()) || _state.inputValue === "") newOptions.push(element)
            })
            return newOptions
          }}
          options={defaultOptions}
          renderOption={({ title, key }: Option) => (
            <Box style={{ color: "#333", textAlign: "left" }} key={key}>
              {title}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              color="primary"
              variant="filled"
              placeholder={placeholder}
              label={label}
              error={!!error}
              helperText={error?.message}
              name={name}
              type="text"
              inputRef={ref}
              {...params}
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
        />
      )}
    />
  )
})
