import { IconButton, InputAdornment } from "@material-ui/core"
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons"
import { useEffect, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { passwordInput } from "./autocomlete-input"
import GenericTextInput from "./genericTextInput"

type Props = {
  form: any
  name: string
  label?: string
  password?: string
  disabled?: boolean
}

const PasswordInput: React.FC<Props> = ({ form, name, password, label = "Пароль", disabled = false }) => {
  const { register, formState, getValues, clearErrors, setError }: UseFormReturn = form
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (password) {
      if (getValues(name) !== password) {
        setError(name, { type: "custom", message: "Пароли не совпадают" })
      } else {
        clearErrors()
      }
    }
  }, [password])

  return (
    <GenericTextInput
      register={register(name, { required: true, minLength: 6 })}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton style={{ fontSize: "120%" }} color="secondary" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </IconButton>
          </InputAdornment>
        ),
        inputProps: { ...(passwordInput as any) }
      }}
      type={showPassword ? "text" : "password"}
      label={label}
      placeholder="Пример: 123456"
      helperText={formState.errors[name]?.message}
      disabled={disabled}
      // onChange={(e: any) => {
      //   // setValue(name, e?.target?.value)
      //   if (password) {
      //     if (password && errors && e?.target?.value !== password) {
      //       setError(name, { type: "custom", message: "Пароли не совпадают" })
      //     } else {
      //       clearErrors()
      //     }
      //   }
      // }}
    />
  )
}

export default PasswordInput
