import React, { useEffect } from "react"

import { DialogProps, Box, Dialog, DialogTitle, DialogContent, useMediaQuery, Theme } from "@material-ui/core"
import { useSelector } from "react-redux"
import { ProjectProps } from "../../store/props/types"
import CloseButton from "./close-button"
import russianRules from "../../utils/russianRules"

export interface Params {
  processed?: boolean
  store?: boolean
  open?: boolean
  name?: string
  closeButton?: boolean
  onCloseDialog?: (r: boolean) => void
  dialogContentClassName?: string
  onOpen?: () => void
}

const Modal: React.FC<Params & DialogProps> = (params) => {
  const {
    processed = false,
    onOpen = () => {},
    store = false,
    name = "default",
    closeButton = true,
    onCloseDialog = (_e: boolean) => {},
    dialogContentClassName = "",
    open,

    fullScreen = false,
    ...props
  } = params

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const { dialog } = useSelector((state: { propsReducer: ProjectProps }) => state.propsReducer)
  const dialogTitleId = `${name}-dialog-title`

  useEffect(() => {
    if (!!dialog?.[name]?.open || open) {
      onOpen()
    }
  }, [dialog, name, open])

  return (
    <>
      <Dialog key={name} scroll="body" disableBackdropClick aria-labelledby={dialogTitleId} fullScreen={fullScreen || xs} open={open} {...props} {...(store ? dialog?.[name] : {})}>
        <DialogTitle id={dialogTitleId} disableTypography>
          {!!props.title && <Box flex="1 1 auto">{russianRules(props.title)}</Box>}
          {closeButton && <CloseButton close={() => onCloseDialog(false)} disabled={processed} />}
        </DialogTitle>
        <DialogContent className={dialogContentClassName}>{props.children}</DialogContent>
      </Dialog>
    </>
  )
}
export default Modal
