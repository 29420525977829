import { useEffect, useState } from "react"
import { Box, Table, TableRow, TableCell, TableHead, TableBody, Hidden, Grid } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import BallView from "../../components/ball/ball"
import { postPublic } from "../../api/actions"
import { Winner } from "../../types/winner"

const countArray = (ar: any) => (ar ? ar.length : 0)

const WinnerList = () => {
  const [ratingTop, setRatingTop] = useState([])
  const [page, setPage] = useState<number>(1)
  const perPage = 5

  const handleChange = (_event: any, value: number) => {
    setPage(value)
  }

  const rating = () => {
    postPublic("/breeder/activity/winner", {}).then((r) => {
      setRatingTop(r.data.data)
      console.log(r.data.data)
    })
  }

  useEffect(() => {
    rating()
  }, [])

  return (
    <>
      <Table key="WinnerList">
        <TableHead>
          <TableRow>
            <Hidden smDown>
              <TableCell>№</TableCell>
              <TableCell>Название питомника</TableCell>
              <TableCell>Телефон</TableCell>
              <TableCell>Начисленные баллы</TableCell>
              <TableCell>Приз</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {ratingTop.length > 0 ? (
            ratingTop.slice(perPage * (page - 1), page * perPage).map((r: Winner, i) => {
              const { id, priz, ball, nursery_name, phone } = r

              return (
                <TableRow key={`exhibition-${id}`}>
                  <Hidden smDown>
                    <TableCell>
                      <Box className="nobr">{i + 1}</Box>
                    </TableCell>
                    <TableCell>
                      <Box className="nobr">{nursery_name || "-"}</Box>
                    </TableCell>
                    <TableCell>
                      <Box className="nobr">{phone}</Box>
                    </TableCell>
                    <TableCell>
                      <BallView>{ball || 0}</BallView>
                    </TableCell>
                    <TableCell>{priz}</TableCell>
                  </Hidden>

                  <Hidden mdUp>
                    <TableCell>
                      <Box textAlign="left" fontSize={14}>
                        <Grid container spacing={1} justify="space-between">
                          <Grid item xs={6}>
                            <Grid container direction="column" spacing={1}>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    №
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    {i + 1}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Название питомника
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    {nursery_name || "-"}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item className="m_cell_title">
                                    Телефон
                                  </Grid>
                                  <Grid item xs={12} className="m_cell">
                                    <Box className="nobr">{phone}</Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container direction="column" alignItems="flex-start" spacing={1}>
                              <Grid item xs={12}>
                                <Box pt={1} fontSize={18}>
                                  <BallView style={{ justifyContent: "flex-start" }}>{ball || 0}</BallView>
                                </Box>
                                <Box className="m_cell_title" pt={2}>
                                  Приз
                                </Box>
                                <Box>{priz}</Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </TableCell>
                  </Hidden>
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6}>Победители будут определены после 16&nbsp;декабря&nbsp;2022&nbsp;г.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {countArray(ratingTop) > perPage && (
        <Box pt={4} textAlign="center">
          <Pagination page={page} variant="text" onChange={handleChange} count={Math.ceil(countArray(ratingTop) / perPage)} />
        </Box>
      )}
    </>
  )
}

export default WinnerList
