import { Box, Container, Grid, useMediaQuery, useTheme, Typography, Button, makeStyles } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useSelector } from "../../hooks"
import TrackEvent from "../../components/GAnalitics"

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: 100,
    color: "#323E48",
    background: 'url("/imgs/bg-all.png") no-repeat top center #E5E5E5',
    "& img": {
      maxWidth: "100%"
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 20,
      fontSize: 14,
      fontWeight: 500
    }
  },

  ball: {
    fontSize: "3vw",
    [theme.breakpoints.down("md")]: {
      fontSize: "4vw",
      lineHeight: 1.5,
      paddingTop: 30
    }
  },

  btn: {
    [theme.breakpoints.down("md")]: {
      bottom: 10,
      left: 0,
      width: "94%",
      marginLeft: "3%",
      textAlign: "center",
      position: "absolute"
    }
  },

  top: {
    background: 'url("/imgs/bg-top.png") no-repeat center',
    backgroundSize: "cover",
    height: "100vh",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,

    [theme.breakpoints.down("md")]: {
      height: "auto",

      background: "#000"
    },
    [theme.breakpoints.down("sm")]: {
      background: 'url("/imgs/top-md.png") no-repeat center #000',
      backgroundPosition: "center right",
      backgroundSize: "cover",
      minHeight: "calc(100vh - 60px)",
      marginTop: 60,
      // background: "#000",
      padding: 0
    },
    [theme.breakpoints.down("xs")]: {
      background: 'url("/imgs/top-xs.png") no-repeat center right #000',
      backgroundSize: "cover"
    }
  },

  topImage: {
    position: "relative",
    left: "50%",
    height: "100%",
    transform: "translateX(-50%)",
    zIndex: 0,
    [theme.breakpoints.up("lg")]: {
      display: "none"
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    //   height: "auto",
    //   marginTop: -50
    // },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: -20,
      height: "auto",
      visibility: "hidden"
    }
  },

  topBody: {
    top: 0,
    left: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 2
  }
}))

const Page = () => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const xs = useMediaQuery(theme.breakpoints.up("md"))
  const { isAuth, userStatus } = useSelector((state) => state.propsReducer)
  // const [msg, setMsg] = useState<Msg>()
  // const [cookies, setCookie] = useCookie("activity")

  const lk = () => {
    TrackEvent({
      eventCategory: "Button",
      eventAction: "click_get_in",
      eventContext: ""
    })

    if (!isAuth) {
      history.push("/?signin")
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" })
      history.push("/profile/#main")
    }
  }
  //
  // useEffect(() => {
  //   if (cookies === null) {
  //     setMsg({
  //       title: "Стань первым в AlphaPet! ",
  //       btnText: "Перейти на страницу Акции",
  //       message: {
  //         alert: [
  //           "С 1 сентября по 15 декабря загружайте анкеты выставок, помётов, владельцев питомцев, делитесь информацией " +
  //             "о корме AlphaPet® в соцсетях, приглашайте друзей-заводчиков в Программу лояльности " +
  //             "AlphaPet® BREEDERS и участвуйте в розыгрыше ценных призов! "
  //         ]
  //       },
  //       onClose: (e: any) => {
  //         setCookie("true")
  //         setMsg(undefined)
  //         if (e === undefined) {
  //           history.push("/promo")
  //         }
  //       }
  //     })
  //   }
  // }, [isAuth])

  return (
    <Box className="animated faster fadeIn">
      {/* <GlobalAlert msg={msg} /> */}
      <Box className={classes.top}>
        {xs && <img src="/imgs/bg-top.png" className={classes.topImage} alt="" />}
        {!xs && <img src="/imgs/top-xs.png" className={classes.topImage} alt="" />}

        <Box className={classes.topBody}>
          <Container maxWidth={false} style={{ height: !xs ? "auto" : "100%" }}>
            <Grid container style={{ height: !xs ? "auto" : "100%" }} alignItems="center">
              <Grid item xs={12} md={6} style={{ height: xs ? "auto" : "100%" }} className="animate__animated  fadeInLeft animate__faster">
                <Typography variant="h1" color="textPrimary" className={classes.ball}>
                  500&nbsp;баллов {xs ? <br /> : " "}
                  за&nbsp;регистрацию {xs ? <br /> : " "}
                  для&nbsp;заводчиков
                  {xs ? <br /> : " "} от&nbsp;AlphaPet<sup>®</sup>&nbsp;BREEDERS
                </Typography>
                <Box py={4} className={classes.btn}>
                  <Button onClick={lk} variant="contained" color="primary" fullWidth={!xs}>
                    участвовать
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      {isAuth && userStatus !== "new" && (
        <Box className={classes.body} id="rules">
          <Container maxWidth="lg">
            <Box>
              <Typography variant="h2" color="secondary" style={{ marginTop: 0, fontSize: "270%" }}>
                Преимущества Программы лояльности
                <br />
                Alphapet<sup>®</sup> breeders
              </Typography>
            </Box>

            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h3">
                  Получите 500&nbsp;приветственных баллов
                  <br />
                  за регистрацию в&nbsp;программе
                  <br />
                  <b>
                    Alphapet<sup>®</sup> breeders
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} style={{ order: !xs ? -1 : 0 }}>
                <img src="/imgs/ball.png" alt="" />
              </Grid>
              <Grid item xs={12}>
                {xs && <Box pt={10} />}
              </Grid>

              <Grid item xs={12} sm={6}>
                <img src="/imgs/product.png" alt="" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h3">
                  Совершите покупку любого корма ТМ&nbsp;Alphapet<sup>®</sup> на любую сумму и&nbsp;получите доступ к&nbsp;опциям
                </Typography>
                <Box pt={5}>
                  <Grid container justify="center" alignItems={!xs ? "flex-start" : "flex-start"} spacing={!xs ? 2 : 0}>
                    <Grid item xs={4} sm={3}>
                      <Box textAlign="center">
                        <img src="/imgs/icon-1.svg" alt="" />
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                      <b>Приведи друга </b> – после регистрации друга по&nbsp;уникальной ссылке Вы получите 500&nbsp;баллов.
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <Box textAlign="center">
                        <img src="/imgs/icon-2.svg" alt="" />
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                      <b>Бонусы за анкеты покупателей</b> – регистрируйте анкеты, получайте баллы после совершения первой покупки новым покупателем.
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <Box textAlign="center">
                        <img src="/imgs/icon-3.svg" alt="" />
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                      <b>Реклама в социальных сетях:</b> <br />
                      <p>- Ежемесячно 50 баллов за&nbsp;наш рекламный баннер у Вас на сайте.</p>
                      <p>
                        - Единоразово 250 баллов за снятый ролик с кормом ТМ&nbsp;AlphaPet<sup>®</sup>, размещенный у Вас на странице в&nbsp;соцсетях.
                      </p>
                      <p>- 100 баллов в месяц за рекламный пост в соцсетях.</p>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <Box textAlign="center">
                        <img src="/imgs/icon-4.svg" alt="" />
                      </Box>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                      <Box pt={2}>
                        <b>Кэшбэк</b> с&nbsp;каждой покупки корма ТМ&nbsp;AlphaPet<sup>®</sup> новым хозяином щенка или котенка.
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {xs && (
                <Grid item xs={12}>
                  <Box pt={10} />
                </Grid>
              )}

              <Grid item xs={12} sm={6} style={{ order: !xs ? 3 : 0 }}>
                <Typography variant="h3">
                  {/* Совершайте покупку корма ТМ&nbsp;AlphaPet<sup>®</sup> на сумму более 10&nbsp;000&nbsp;₽ */}
                  Регистрируйте щенков и котят на нашем сайте и получайте:
                </Typography>

                <ul>
                  <li>Подарки на каждого котенка или щенка</li>
                  <li>Программы для выкорма пометов</li>
                </ul>
                {/* Получите возможность заказать бесплатный подарок на помёт или подарок новым хозяевам своих щенков */}

                <Box>
                  <Grid container justify="center">
                    <Grid item xs={2} sm={4} />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} style={{ order: !xs ? 2 : 0 }}>
                <img src="/imgs/prize.png" alt="" />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ order: !xs ? 4 : 0 }}>
              <Box pt={{ xs: 5, sm: 5, md: 10, lg: 10 }} pb={{ xs: 5, sm: 5, md: 10, lg: 10 }} textAlign="center">
                <Button fullWidth={!xs} onClick={lk} variant="contained" color="primary">
                  участвовать
                </Button>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  )
}
export default Page
