import { Box, Container, Grid, useMediaQuery, useTheme, Typography, Button, makeStyles } from "@material-ui/core"
import { useHistory } from "react-router-dom"

import { useSelector } from "../../hooks"
import WinnerList from "./winner-list"

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: 100,
    color: "#323E48",
    background: "#fff",
    "& img": {
      maxWidth: "100%"
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 20,
      fontSize: 14,
      fontWeight: 500
    }
  },

  ball: {
    fontSize: "3vw",
    [theme.breakpoints.down("md")]: {
      fontSize: "5vw",
      lineHeight: 1.5,
      paddingTop: 30
    }
  },

  btn: {
    [theme.breakpoints.down("md")]: {
      bottom: 10,
      left: 0,
      width: "94%",
      marginLeft: "3%",
      textAlign: "center",
      position: "absolute"
    }
  },

  top: {
    background: 'url("/imgs/promo/bg.jpg") no-repeat center',
    backgroundSize: "cover",
    height: "100vh",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,

    [theme.breakpoints.down("md")]: {
      backgroundPosition: "60% center"
    },
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "80% center",
      backgroundSize: "cover",
      minHeight: "calc(100vh - 60px)",
      marginTop: 60,
      // background: "#000",
      padding: 0
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "cover"
    }
  },

  topImage: {
    position: "relative",
    left: "50%",
    height: "100%",
    transform: "translateX(-50%)",
    zIndex: 0,
    [theme.breakpoints.up("lg")]: {
      display: "none"
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    //   height: "auto",
    //   marginTop: -50
    // },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: -20,
      height: "auto",
      visibility: "hidden"
    }
  },

  topBody: {
    top: 0,
    left: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 2
  },
  prize: {
    color: "#323E48",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 26,

    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "2vw"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "19px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "4vw"
    }
  },
  prizeName: {
    fontSize: "70%",
    paddingBottom: 15
  },
  prizeBox: {
    padding: 20,
    margin: "15px 20px",
    backgroundColor: "#F0EDEC",
    [theme.breakpoints.down("md")]: {
      margin: "15px 15px",
      padding: 15
    }
  },
  rules: {
    fontSize: 19,
    fontWeight: 600,
    color: "#323E48",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5vw"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "19px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "4vw"
    },
    "& img": {
      maxWidth: "none",
      [theme.breakpoints.down("md")]: {
        width: "10vw"
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "150px",
        width: "20vw"
      }
    }
  }
}))

const Page = () => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const xs = useMediaQuery(theme.breakpoints.up("md"))
  const { isAuth } = useSelector((state) => state.propsReducer)

  const lk = () => {
    if (!isAuth) {
      history.push("/promo/?signin")
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" })
      history.push("/profile/#promo")
    }
  }

  return (
    <Box className="animated faster fadeIn">
      <Box className={classes.top}>
        <Box className={classes.topBody}>
          <Container maxWidth={false} style={{ height: !xs ? "auto" : "100%" }}>
            <Grid container style={{ height: !xs ? "auto" : "100%" }} alignItems="center">
              <Grid item xs={12} md={6} style={{ height: xs ? "auto" : "100%" }} className="animate__animated  fadeInLeft animate__faster">
                <Typography variant="h1" color="textPrimary" className={classes.ball}>
                  Стань первым
                  {xs ? <br /> : " "} в&nbsp;AlphaPet<sup>® *</sup>
                </Typography>
                <Box py={4} className={classes.btn}>
                  <Button onClick={lk} variant="contained" color="primary" fullWidth={!xs}>
                    участвовать
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box className={classes.body} pb={6}>
        <Container maxWidth="lg">
          <Box>
            <Typography variant="h2" color="secondary" style={{ marginTop: 0, fontSize: "270%" }}>
              Правила
            </Typography>
          </Box>
          <Box pt={2} pb={5}>
            <Grid container justify="center">
              <Grid item xs={12} sm={8} md={4} className={classes.rules}>
                <Grid container direction="row" wrap="nowrap" alignItems="center">
                  <Grid item xs="auto">
                    <img src="/imgs/promo/1.svg" alt="1" />
                  </Grid>
                  <Grid item xs="auto">
                    Используйте все опции программы лояльности AlphaPet<sup>®</sup> BREEDERS
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} md={4} className={classes.rules}>
                <Grid container direction="row" wrap="nowrap" alignItems="center">
                  <Grid item xs="auto">
                    <img src="/imgs/promo/2.svg" alt="2" />
                  </Grid>
                  <Grid item xs="auto">
                    Получайте баллы
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} md={4} className={classes.rules}>
                <Grid container direction="row" wrap="nowrap" alignItems="center">
                  <Grid item xs="auto">
                    <img src="/imgs/promo/3.svg" alt="3" />
                  </Grid>
                  <Grid item xs="auto">
                    Заводчики, набравшие большее количество баллов, получат призы
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={4} pb={6}>
            <Typography variant="h2" color="secondary" style={{ marginTop: 0, fontSize: "270%" }}>
              Призовой фонд
            </Typography>
          </Box>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={8} md={4}>
              <Box className={classes.prize}>
                <Box>1 место</Box>
                <Box className={classes.prizeBox}>
                  <Box>
                    <img src="/imgs/promo/p1.png" alt="" />
                  </Box>
                  <Box className={classes.prizeName}>годовой запас корма</Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={4}>
              <Box className={classes.prize}>
                <Box>2 место</Box>
                <Box className={classes.prizeBox}>
                  <Box>
                    <img src="/imgs/promo/p2.png" alt="" />
                  </Box>
                  <Box className={classes.prizeName}>Телевизор</Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={4}>
              <Box className={classes.prize}>
                <Box>3 место</Box>
                <Box className={classes.prizeBox}>
                  <Box>
                    <img src="/imgs/promo/p3.png" alt="" />
                  </Box>
                  <Box className={classes.prizeName}>Сертификаты OZON</Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box pt={3} textAlign="center">
                <Button onClick={lk} variant="contained" color="primary">
                  Посмотреть рейтинг
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box pt={8} pb={6}>
            <Typography variant="h2" color="secondary" style={{ marginTop: 0, fontSize: "270%" }}>
              Победители
            </Typography>
          </Box>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
              <WinnerList />
            </Grid>
          </Grid>
          <Box pt={4}>* Подведение итогов 16&nbsp;декабря&nbsp;2022г.</Box>
        </Container>
      </Box>
    </Box>
  )
}
export default Page
