import { date, breed_species_id, address, validation, imageDropzone } from "../../../../components/form-control/validation"

const validationShema = validation({
  date_at: date,
  breed_species_id,
  address,
  image_file: imageDropzone
})

export default validationShema
