const Address: React.FC<{ city?: string; area?: string; np?: string; region?: string }> = ({ city, region, area, np }) => {
  return (
    <>
      {(city || area || np) && region ? `${region}, ` : region}
      {(area || np) && city ? `${city}, ` : city}
      {np && area ? `${area}, ` : area}
      {np}
    </>
  )
}

export default Address
