import React from "react"
import Popper from "@material-ui/core/Popper"
import { Box, Hidden, Link, useMediaQuery, useTheme, Fade, ClickAwayListener, makeStyles, Button } from "@material-ui/core"
import useReactRouter from "use-react-router"
import { useDispatch } from "react-redux"
import MyLink from "../menu/myLink"
import { User } from "../../../types/user"
import { SET_PROPS } from "../../../store/props/types"
import { modalName as ChangePasswordModal } from "../../../modal/change-password"

const phoneFormat = (phone: string) => (phone !== null && phone && phone.length > 9 ? `+7 (${phone.substring(0, 3)}) ${phone.substring(3, 6)} ${phone.substring(6, 8)} ${phone.substring(8)}` : phone)

const useStyles = makeStyles((theme) => ({
  popperBody: {
    color: "#fff",
    fontSize: 16,
    fontWeight: 400,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      color: "#8C9297",
      fontWeight: 500,
      "& b": {
        fontSize: 14,
        fontWeight: 600,
        color: "#AD7D66"
      }
    }
  },

  popperArrow: {
    position: "absolute",
    top: 0,
    right: "15%",
    background: "#AD7D66",
    width: 15,
    height: 15,
    transform: "translateY(-50%) rotate(45deg)"
  },
  popper: {
    right: 0,
    width: 250,
    background: "#AD7D66",
    borderRadius: 0,
    marginTop: 25,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      background: "#E9F0F2",
      width: "100%",
      marginTop: 0
    }
  },
  popperInfo: {
    padding: "0px 20px",
    textAlign: "left"
  },
  text: {
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "inline-block"
  },
  btn: {
    fontSize: 14,
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      color: "#AD7D66"
    }
  },
  textBtn: {
    fontSize: 14,
    color: "#E9F0F2",
    textTransform: "none",
    padding: 0,
    textDecoration: "underline"
  }
}))

type Prop = { user: User; classesItem: any }

const PopperAuth: React.FC<Prop> = ({ user, classesItem }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const { history, location } = useReactRouter()
  const { pathname } = location

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.up("md"))

  const handleClickClosePoper = () => {
    setOpen(false)
  }

  // eslint-disable-next-line consistent-return
  const handleClickLk = (event: any) => {
    if (pathname.indexOf("/profile") >= 0) {
      event.preventDefault()
      setAnchorEl(event.currentTarget)
      setOpen(!open)
      return false
    }
  }

  const handleClickExit = () => {
    handleClickClosePoper()
    history.push({ pathname: "/signout" })
  }

  const changePassword = () => {
    handleClickClosePoper()
    dispatch({
      type: SET_PROPS,
      payload: { modal: { [ChangePasswordModal]: { show: true } } }
    })
  }

  return (
    <>
      <Link component={MyLink} to="/profile/#main" className={classesItem}>
        <Box onClick={handleClickLk}>
          {xs && <img src="/imgs/user.svg" style={{ marginBottom: -10, marginTop: -10, marginRight: 10 }} alt="" />} {xs && (user?.first_name || "Личный кабинет")}
        </Box>
      </Link>

      <Hidden mdUp>
        <Box className={classes.popper}>
          <Box py={2} className={classes.popperBody}>
            <Box className={classes.popperInfo}>
              <Box
                style={{ textTransform: "uppercase" }}
                pb={1}
                pt={2}
                className={classes.text}
                onClick={() => {
                  history.push("/profile/#main")
                }}
              >
                <img src={xs ? "/imgs/user-w.svg" : "/imgs/user.svg"} style={{ color: "#fff", marginBottom: -10, marginTop: -10, marginRight: 10 }} alt="" />
                <b>{user?.first_name}</b>
              </Box>

              <Box>Тел.: {phoneFormat(user?.phone)}</Box>
              <Box className={classes.text}>E-mail: {user?.email}</Box>
              <Box className={classes.text}>
                <Button onClick={changePassword} variant="text" color="primary" style={{ paddingLeft: 0 }}>
                  Сменить пароль
                </Button>
              </Box>
              <Box width="100%" textAlign="right" pt={2}>
                <Button onClick={handleClickExit} className={classes.btn}>
                  Выход
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Hidden smDown>
        <Popper open={open} anchorEl={anchorEl} transition className={classes.popper} placement="bottom-end" disablePortal>
          <ClickAwayListener mouseEvent="onClick" onClickAway={handleClickClosePoper}>
            <div>
              <Fade in={open} timeout={250}>
                <Box py={2} className={classes.popperBody}>
                  <Box className={classes.popperInfo}>
                    <Box style={{ textTransform: "uppercase" }} pb={1} pt={2} className={classes.text}>
                      <img src="/imgs/user-w.svg" style={{ color: "#fff", marginBottom: -10, marginTop: -10, marginRight: 10 }} alt="" />
                      <b>{user?.first_name}</b>
                    </Box>

                    <Box> {phoneFormat(user?.phone)}</Box>
                    <Box className={classes.text}> {user?.email}</Box>
                    <Box className={classes.text}>
                      <Button className={classes.textBtn} onClick={changePassword} variant="text" color="default" style={{ color: "#fff" }}>
                        Сменить пароль
                      </Button>
                    </Box>
                    <Box width="100%" textAlign="right" pt={2}>
                      <Button onClick={handleClickExit} className={classes.btn}>
                        Выход
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Fade>
              <span className={classes.popperArrow} />
            </div>
          </ClickAwayListener>
        </Popper>
      </Hidden>
    </>
  )
}

export default PopperAuth
