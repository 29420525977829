import { useState } from "react"
import { AppBar, Box, Grid, Tab, Tabs, Typography, useTheme, useMediaQuery } from "@material-ui/core"
import useRouter from "use-react-router"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"
import TabPanel from "../../../components/tab-panel"
import BallList from "../ball-list"
import Friends from "../friends/friends"
import { User } from "../../../types/user"
import Prize from "../shop/shop"
import PrizeList from "../prize-list"
import SocialAuth from "../../../components/social-auth"
import UpdateSubscribe from "../../../components/update-subscribe"
import Ball from "../../../components/ball/ball"
import useStyles from "./style"

const Profile: React.FC<{ user: User; loadInfo: any }> = ({ user, loadInfo }) => {
  const classes = useStyles()

  const router = useRouter()
  const [tab, setTab] = useState<number>(0)
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  const { balance_score, received_score, spent_score, breeder_balance, invite_code, breeder_priz }: User = user || {}

  const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }

  const allowBlock = (need: string) => {
    return user?.breeder_level_current?.breeder_level_option_current.filter((v) => v.key === need).length <= 0
  }

  const handleChangeTab = (_e: any, newValue: number) => {
    setTab(newValue)
  }

  if (router.location.hash === "") {
    router.history.push("#main")
  }

  return (
    <Grid container justify="center" className="animated fadeIn faster" spacing={4}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container direction="column" style={{ height: "100%" }}>
          <Grid item style={{ flex: "0 1 auto", minHeight: md ? 70 : 20 }}>
            <Grid container alignItems="center" alignContent="center" spacing={lg ? 2 : 0}>
              <Grid item xs={12} md="auto">
                <Typography variant="h4" color="secondary">
                  МОИ БАЛЛЫ
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ flex: "1 1 auto" }}>
            <Grid container alignItems="stretch" spacing={2} style={{ height: "100%" }}>
              <Grid item xs={12} sm={6}>
                <Grid container style={{ height: "100%" }} alignItems="center" className={classNames(classes.box1, "animated", "zoomIn faster")}>
                  <Grid item xs={12}>
                    <Box className="label">Текущий баланс</Box>
                    <Ball>{balance_score}</Ball>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={12}>
                    <Box className={classNames(classes.box2, "animated", "zoomIn faster")}>
                      <Box className="label">Начислено</Box>
                      <Ball>{received_score}</Ball>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <Box className={classNames(classes.box2, "animated", "zoomIn faster")}>
                      <Box className="label">Использовано</Box>
                      <Ball>{spent_score}</Ball>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {/* <Grid container direction="column" style={{ height: "100%" }}> */}
        {/*  <Grid item style={{ flex: "0 1 auto", minHeight: md ? 70 : 20, paddingBottom: md ? 0 : 10 }}> */}
        {/*    <Grid container alignItems="center" spacing={2}> */}
        {/*      <Grid item xs="auto" md="auto"> */}
        {/*        <Typography variant="h4" color="secondary"> */}
        {/*          МОИ ПОКУПКИ */}
        {/*        </Typography> */}
        {/*      </Grid> */}
        {/*      <Grid item xs="auto" md="auto" style={{ fontSize: 25 }}> */}
        {/*        <Box style={{ marginTop: -15, fontSize: "80%" }}> */}
        {/*          <Status comment="Информация о&nbsp;покупках появится в&nbsp;течение 10&nbsp;дней после оплаты заказа." /> */}
        {/*        </Box> */}
        {/*      </Grid> */}
        {/*    </Grid> */}
        {/*  </Grid> */}
        {/*  <Grid item style={{ flex: "1 1 auto" }}> */}
        {/*    <Grid container alignItems="stretch" spacing={md ? 0 : 2} style={{ height: "100%" }}> */}
        {/*      <Grid item xs={12} sm={5} style={{ paddingRight: md ? 15 : 5 }}> */}
        {/*        <Grid */}
        {/*          container */}
        {/*          style={{ height: "100%" }} */}
        {/*          alignItems="center" */}
        {/*          className={classNames(classes.box1, "animated faster zoomIn")} */}
        {/*        > */}
        {/*          <Grid item xs={12}> */}
        {/*            <Box className="label">скидка</Box> */}
        {/*            <Box className="ball" style={{ justifyContent: "center" }}> */}
        {/*              {breeder_level_current?.breeder_level_parameter_current?.discount}% */}
        {/*            </Box> */}
        {/*          </Grid> */}
        {/*        </Grid> */}
        {/*      </Grid> */}
        {/*      <Grid item xs={12} sm={7}> */}
        {/*        <Grid */}
        {/*          container */}
        {/*          style={{ height: "100%" }} */}
        {/*          alignItems="center" */}
        {/*          className={classNames(classes.box2, "animated faster zoomIn")} */}
        {/*        > */}
        {/*          <Grid item xs={12}> */}
        {/*            <Box p={1}> */}
        {/*              <Box className="label"> */}
        {/*                В&nbsp;среднем в&nbsp;месяц вы */}
        {/*                <br /> покупаете&nbsp;на */}
        {/*              </Box> */}
        {/*              <Box className="ball" style={{ justifyContent: "center" }}> */}
        {/*                <NumberFormat */}
        {/*                  displayType="text" */}
        {/*                  thousandSeparator="&nbsp;" */}
        {/*                  value={breeder_level_current?.pivot?.summa} */}
        {/*                  suffix="&nbsp;&#8381;" */}
        {/*                /> */}
        {/*              </Box> */}
        {/*            </Box> */}
        {/*          </Grid> */}
        {/*        </Grid> */}
        {/*      </Grid> */}
        {/*    </Grid> */}
        {/*  </Grid> */}
        {/* </Grid> */}
      </Grid>

      <Grid item xs={12} className={classNames("animated faster fadeIn delay-1s")}>
        <Prize scope={balance_score} user={user} active loadInfo={loadInfo} />
      </Grid>

      {!allowBlock("offspring") && (
        <Grid item xs={12} className={classNames("animated faster fadeIn")}>
          <Box>
            <Typography variant="h4" color="secondary">
              Пригласи друга
            </Typography>
          </Box>
          <Friends code={invite_code} />
        </Grid>
      )}

      <Grid item xs={12}>
        <Box mt={2} mb={2}>
          <AppBar position="static">
            <Tabs value={tab} onChange={handleChangeTab} style={{ margin: "0 auto" }}>
              <Tab label="БАЛЛЫ" {...a11yProps(0)} />
              <Tab label="ПРИЗЫ" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TabPanel value={tab} index={0} className={classNames(classes.tabPanel, "animated", "fadeIn")}>
          <Box className={classes.list}>
            <BallList items={breeder_balance} />
          </Box>
        </TabPanel>

        <TabPanel value={tab} index={1} className={classNames(classes.tabPanel, "animated", "fadeIn")}>
          <Box className={classes.list}>
            <PrizeList items={breeder_priz} />
          </Box>
        </TabPanel>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box width="100%" maxWidth={500} margin="0 auto">
          {user && <UpdateSubscribe user={user} loadInfo={loadInfo} />}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box py={2} margin="0 auto" width="100%" maxWidth={500}>
          {user && <SocialAuth userProvider={user.social} className="add_sn_lk" eventCategory="Add_social" eventAction="over_social_a" label="Привязать соцсеть" />}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Profile
