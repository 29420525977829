import { Controller, UseFormReturn } from "react-hook-form"

import { DropzoneDialog } from "material-ui-dropzone"
import { useEffect, useState } from "react"
import { Box, Button, Grid, IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core"
import { CloseOutlined } from "@material-ui/icons"
import { GlobalAlertModal } from "../../modal/globalAlert"
import { Msg } from "../../modal/globalAlert/alert"
import Answer from "../../utils/answer"

type Props = {
  form: any
  name: string
  label?: string
  disabled?: boolean
  placeholder?: string
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    "& input": {
      paddingLeft: 140
    },
    "&>div": {
      paddingRight: "0px!important"
    }
  },
  btn: {
    position: "absolute",
    left: 6,
    top: 6
  }
}))

const DropzoneInput: React.FC<Props> = ({ form, name, label = "Добавить файлы", placeholder = "Загрузите или перетяните сюда файл" }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [files, setFiles] = useState<any[]>([])
  const [msg, setMsg] = useState<Msg>()
  const { control }: UseFormReturn = form
  const classes = useStyles()

  useEffect(() => {
    console.log(files)
  }, [files])

  const getIcon = (file: any) => {
    if (file !== null) {
      switch (file?.type) {
        case "image/jpeg":
        case "image/png":
          return URL.createObjectURL(file)
        case "application/pdf":
          return "/imgs/pdf.svg"
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/msword":
          return "/imgs/docx.svg"

        default:
          return ""
      }
    }
    return ""
  }

  return (
    <Box>
      <GlobalAlertModal msg={msg} />

      <Controller
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Box className={classes.root}>
            <DropzoneDialog
              maxWidth="xs"
              clearOnUnmount={false}
              filesLimit={5}
              showFileNamesInPreview
              dialogTitle={label}
              acceptedFiles={["image/jpeg", "application/pdf", "image/png", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]}
              cancelButtonText="отмена"
              submitButtonText="выбрать"
              previewText="Предпросмотр:"
              dropzoneText={placeholder}
              getFileLimitExceedMessage={(fileName) => {
                return `Максимальное кол-во файлов: ${fileName}`
              }}
              getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                console.log(rejectedFile, acceptedFiles, maxFileSize, error)
                // TODO: дописать ошибку на размер файла и на тип.
                if (!acceptedFiles.includes(rejectedFile.type)) {
                  return `Выберите файлы в формате jpg, jpeg, png, doc, docx или pdf`
                }

                if (maxFileSize < rejectedFile.size) {
                  return `Размер файла не должен превышать ${maxFileSize / 1000000} Мб`
                }

                return `Ошибка добавления файла ${rejectedFile.name}`
              }}
              onAlert={(message, variant) => {
                console.log(message, `e:${variant}`)

                if (variant === "error") {
                  setMsg({
                    status: 0,
                    message: Answer(message),
                    onClose: () => {
                      setMsg(undefined)
                    }
                  })
                }
              }}
              maxFileSize={10000000}
              fullWidth={false}
              open={open}
              onClose={() => {
                setOpen(false)
              }}
              onSave={(f) => {
                console.log("Files:", f)
                setOpen(false)
                setFiles(f)
                field.onChange(f)
              }}
              showPreviews
              showAlerts={false}
              previewGridProps={{ container: { spacing: 1, direction: "row" } }}
              getPreviewIcon={(file) => {
                if (file.file.type.split("/")[0] === "image") {
                  return <img alt={file.file.name} style={{ height: "70px" }} role="presentation" src={(file?.data as string) || ""} />
                }
                if (file.file.type === "application/pdf") {
                  return <img alt={file.file.name} style={{ height: "70px" }} role="presentation" src="/imgs/pdf.svg" />
                }
                if (file.file.type.split("/")[0] === "application") {
                  return <img alt={file.file.name} style={{ height: "70px" }} role="presentation" src="/imgs/docx.svg" />
                }
                return <img alt={file.file.name} style={{ height: "70px" }} role="presentation" src="/imgs/docx.svg" />
              }}
            />
            <Box>
              <TextField
                label={label}
                fullWidth
                color="primary"
                variant="filled"
                disabled
                helperText={error?.message}
                className={classes.root}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {files.length > 0 && (
                        <IconButton
                          onClick={() => {
                            setFiles([])
                            field.onChange(undefined)
                          }}
                          onMouseDown={(event: any) => {
                            event.preventDefault()
                          }}
                          style={{ fontSize: "120%" }}
                          color="secondary"
                          aria-label="toggle password visibility"
                        >
                          <CloseOutlined />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
                value={`Выбрано файлов: ${files.length}`}
              />
              <Button className={classes.btn} type="button" variant="contained" color="secondary" size="small" onClick={() => setOpen(true)}>
                Добавить фото
              </Button>
            </Box>
          </Box>
        )}
        name={name}
        control={control}
        defaultValue=""
      />
      {files && files.length > 0 && (
        <Box pb={6}>
          <Grid container spacing={1}>
            {files?.map((f: any, i: number) => {
              return (
                <Grid
                  item
                  xs={6}
                  md={3}
                  key={i}
                  style={{
                    textAlign: "center"
                  }}
                >
                  <Box p={1} style={{ backgroundColor: "#F4F4F4" }}>
                    <Box
                      style={{
                        margin: "0px auto",
                        height: "80px",
                        aspectRatio: "1 / 1",
                        backgroundImage: `url('${getIcon(f)}')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                      }}
                    />
                    <Box
                      style={{
                        fontSize: "12px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%"
                      }}
                    >
                      {f.name}
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default DropzoneInput
