import { Box, IconButton, Grid, createStyles, makeStyles, useTheme, useMediaQuery } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVk } from "@fortawesome/free-brands-svg-icons"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"
import TrackEvent from "../GAnalitics"

const { API_URL } = window.APP_CONFIG

const urls: any = (url: string) => {
  return `${API_URL}/social/${url.replace(/^\/+/, "")}`
}

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      fontSize: 18,
      color: "#fff",
      width: 32,
      height: 32,
      padding: 0,
      borderRadius: 3,
      // backgroundColor: theme.palette.primary,
      "& span": {
        width: "auto"
      },
      "&:hover": {
        backgroundColor: "'#8E583E'"
        // color: theme.palette.other
      },
      "&:disabled": {
        backgroundColor: "#8E583E"
      },
      "&.ok": {
        backgroundColor: "#AD7D66"
      },
      "&.vk": {
        backgroundColor: "#AD7D66"
      },
      "&.fb": {
        backgroundColor: "#AD7D66"
      }
    },
    label: {
      textAlign: "left",
      fontSize: "18px!important",

      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        textAlign: "left"
      }
    },
    containerSocial: {
      "& .container-items": {
        "&>*": {
          "&:not(:first-child)": {
            marginLeft: theme.spacing(1)
          },
          "&:not(:last-child)": {
            marginRight: theme.spacing(1)
          }
        }
      }
    }
  })
)

const onClickSocialAuth = ({ name, eventAction, eventCategory }: PropSoc) => {
  TrackEvent({ eventCategory, eventAction, eventLabel: `${name}` })
  window.location.href = urls(`/signin/${name}`)
}

type PropSoc = {
  name?: string
  eventAction: string
  eventCategory: string
}
type Prop = {
  className?: any
  classNameLabel?: any
  label?: any
  userProvider: { provider: string }[]
  disabled?: boolean
  eventAction: string
  eventCategory: string
}

const SocialAuth: React.FC<Prop> = ({ className, classNameLabel, label = "", userProvider = [], eventAction = "", eventCategory = "", disabled }) => {
  const classes = useStyles()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up("sm"))

  const IsSocialProvider = (provider: string) => {
    for (let i = 0; i < userProvider.length; i++) {
      const item = userProvider[i]
      if (item.provider === provider) return true
    }
    return false
  }

  return (
    <Box py={2}>
      <Grid container justify="space-between" spacing={2} alignItems="center" className={classNames(classes.containerSocial, className)}>
        <Grid item xs={12} sm={6} className={classNames(classes.label, classNameLabel)}>
          {label}
        </Grid>

        <Grid item xs={12} sm={6} className="container-items">
          <Grid container spacing={1} justify={sm ? "flex-end" : "center"}>
            <Grid item xs="auto">
              <IconButton
                onClick={() => {
                  onClickSocialAuth({
                    name: "vkontakte_breeder",
                    eventAction,
                    eventCategory
                  })
                }}
                type="submit"
                color="primary"
                disabled={disabled || IsSocialProvider("vkontakte_breeder")}
                className={[classes.iconButton, "vk"].join(" ")}
              >
                <span className="fa-fw fa-1x">
                  <FontAwesomeIcon icon={faVk} size="1x" />
                </span>
              </IconButton>
            </Grid>
            {/* <Grid item xs="auto"> */}
            {/*  <IconButton */}
            {/*    onClick={() => { */}
            {/*      onClickSocialAuth({ */}
            {/*        name: "facebook_breeder", */}
            {/*        eventAction, */}
            {/*        eventCategory */}
            {/*      }) */}
            {/*    }} */}
            {/*    type="submit" */}
            {/*    color="primary" */}
            {/*    disabled={disabled || IsSocialProvider("facebook_breeder")} */}
            {/*    className={[classes.iconButton, "fb"].join(" ")} */}
            {/*  > */}
            {/*    <span className="fa-fw fa-1x"> */}
            {/*      <FontAwesomeIcon icon={faFacebookF} size="1x" /> */}
            {/*    </span> */}
            {/*  </IconButton> */}
            {/* </Grid> */}
            {/* <Grid item xs="auto"> */}
            {/*  <IconButton */}
            {/*    onClick={() => { */}
            {/*      onClickSocialAuth({ */}
            {/*        name: "odnoklassniki_breeder", */}
            {/*        eventAction, */}
            {/*        eventCategory */}
            {/*      }) */}
            {/*    }} */}
            {/*    type="submit" */}
            {/*    color="primary" */}
            {/*    disabled={disabled || IsSocialProvider("odnoklassniki_breeder")} */}
            {/*    className={[classes.iconButton, "ok"].join(" ")} */}
            {/*  > */}
            {/*    <span className="fa-fw fa-1x"> */}
            {/*      <FontAwesomeIcon icon={faOdnoklassniki} size="1x" /> */}
            {/*    </span> */}
            {/*  </IconButton> */}
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SocialAuth
