import { Grid, Link, useMediaQuery, useTheme, Container, makeStyles } from "@material-ui/core"
import { Link as ScrollLink, scroller } from "react-scroll"
import useReactRouter from "use-react-router"
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from "classnames"
import MyLink from "./myLink"
import { useSelector } from "../../../hooks"
import PopperAuth from "../header/popperAuth"

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      marginLeft: -15,

      "& img": {
        maxWidth: 120,
        marginBottom: 20
      },
      "&:after": {
        left: 0,
        top: 60,
        position: "absolute",
        content: '""',
        height: 1,
        width: "100%"
        // borderBottom: "1px solid #DAD0CB"
      }
    }
  },
  menuBody: {
    paddingBottom: 10,
    paddingTop: 10,
    border: "1px solid transparent",
    "&.black": {
      borderBottom: "1px solid #E0E0E0 "
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  menuItem: {
    textAlign: "center",
    marginLeft: 30,

    "& a": {
      cursor: "pointer",
      textDecoration: "none!important",
      fontWeight: 600,
      fontSize: 16,
      color: "#fff",
      position: "relative",
      "&.active": {
        color: "#fff"
      },
      [theme.breakpoints.down("sm")]: {
        color: "#323E48",
        textTransform: "uppercase",
        "&.active": {
          color: "#323E48"
        }
      }
    },
    "&.black a": {
      color: "#323E48",
      "&.active": {
        color: "#AD7D66"
      }
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      // borderBottom: "1px solid #DAD0CB",
      // paddingLeft: "0px!important",
      width: "100%",
      marginLeft: 0
    }
  }
}))

const Menu = () => {
  const { history, location } = useReactRouter()
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.up("md"))

  const { pathname } = location
  const { isAuth, userStatus, load } = useSelector((state) => state.propsReducer)
  const { data: user } = useSelector((state) => state.authReducer)

  const handleClick = (key: string) => {
    if (window.location.pathname !== "") {
      history.push(`/#${key}`)
      setTimeout(() => {
        scroller.scrollTo(key, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart"
        })
        document?.getElementById("menubtn")?.click()
      }, 100)
    }
  }

  const scrollLinks = {
    rules: "Правила"
  }

  const isBlack = () => {
    const a = ["/", "/promo"]
    return !a.includes(pathname)
  }

  return (
    <Container maxWidth={false} className={classNames(classes.menuBody, isBlack() ? "black" : "123")}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <Link to="/" component={MyLink} className={classes.logo}>
            {!isBlack() && xs && load && <img alt="AlphaPet" src="/imgs/logo-top.svg" />}
            {(isBlack() || !load || !xs) && <img alt="AlphaPet" style={{ marginLeft: 15 }} src="/imgs/logo-black.svg" />}
          </Link>
        </Grid>

        <Grid item xs={12} md="auto">
          <Grid container justify="flex-end" alignItems="center" spacing={4}>
            {isAuth && !xs && (
              <>
                <Grid item xs={12} md="auto" className={classNames(classes.menuItem, isBlack() || !load ? "black" : "")} style={{ padding: !xs ? "0px 0" : "10px" }}>
                  <PopperAuth user={user} classesItem="menu_item_in" />
                </Grid>
              </>
            )}

            {isAuth &&
              userStatus !== "new" &&
              Object.entries(scrollLinks).map(([key, val]) => (
                <Grid key={key} item xs={12} md="auto" className={classNames(classes.menuItem, isBlack() || !load ? "black" : "")}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link component={ScrollLink} className={key} onClick={() => handleClick(key)} activeClass="active" to={key} spy hashSpy smooth duration={700}>
                    {val}
                  </Link>
                </Grid>
              ))}

            {/* <Grid item xs={12} md="auto" className={classNames(classes.menuItem, isBlack() || !load ? "black" : "")}> */}
            {/*  <Link component={MyLink} to="/promo"> */}
            {/*    Акция */}
            {/*  </Link> */}
            {/* </Grid> */}

            {!isAuth && (
              <Grid item xs={12} md="auto" className={classNames(classes.menuItem, isBlack() || !load ? "black" : "")}>
                <Link component={MyLink} to="/?signup">
                  Регистрация
                </Link>
              </Grid>
            )}

            {!isAuth && (
              <Grid item xs={12} md="auto" className={classNames(classes.menuItem, isBlack() || !load ? "black" : "")}>
                <Link component={MyLink} to="?signin">
                  Вход
                </Link>
              </Grid>
            )}

            {isAuth && xs && (
              <>
                <Grid item xs={12} md="auto" className={classNames(classes.menuItem, isBlack() || !load ? "black" : "")} style={{ padding: !xs ? "5px 0" : "10px" }}>
                  <PopperAuth user={user} classesItem="menu_item_in" />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Menu
