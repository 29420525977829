import { Box, Typography, Button } from "@material-ui/core"
import Slider from "react-slick"
import { useEffect, useState } from "react"
import { get, post } from "../../../api/actions"
import { GlobalAlertModal } from "../../../modal/globalAlert"
import { PrizeType } from "../../../types/prize"
import ConfirmModal from "../../../modal/confirm"
import { Msg } from "../../../modal/confirm/confirm"
import { Msg as MsgAlert } from "../../../modal/globalAlert/alert"
import Ball from "../../../components/ball/ball"
import { User } from "../../../types/user"
import SubmitButton from "../../../components/submit-button"
import Answer from "../../../utils/answer"
import useStyles from "./style"
import { PrizeInfoModal } from "../../../modal/prize-info/prize-info"
import russianRules from "../../../utils/russianRules"

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const Prize = ({ scope = 0, loadInfo, active, user }: { scope: number; loadInfo: any; active: boolean; user: User }) => {
  const classes = useStyles()
  const [msg, setMsg] = useState<Msg>()
  const [msgInfo, setMsgInfo] = useState<Msg>()
  const [processed, setProcessed] = useState<boolean>(false)
  const [msgAlert, setMsgAlert] = useState<MsgAlert>()
  const [prizs, setPrizs] = useState<PrizeType[]>([])
  const { need_ndfl } = user || {}

  useEffect(() => {
    get("/user/breeder/priz/get", {}).then((res) => {
      setPrizs(res.data as unknown as PrizeType[])
    })
  }, [])

  const handleOrder = (p: PrizeType) => {
    setMsgInfo({
      message: "",
      onClose: () => {
        setMsgInfo(undefined)
      },
      prize: p,
      scope
    })
  }

  const handleOrderPrize = (p: PrizeType) => {
    setMsgInfo(undefined)
    if (!need_ndfl || (need_ndfl && !p.need_ndfl)) {
      setMsg({
        message: "",
        onClose: () => {
          setMsg(undefined)
          loadInfo()
        },
        prize: p,
        scope
      })
    } else {
      setMsgAlert({
        message: " ",
        text: "Сумма всех призов свыше 4 000 руб., необходимо заполнить налоговую форму или Ваша форма ещё на проверке. Вы можете сделать заказ после проверки/заполнения формы",
        onClose: () => {
          setMsgAlert(undefined)
          loadInfo()
        }
      })
    }
  }

  const handlePersona = () => {
    post(`/user/breeder/personal/request`, { type: 2 })
      .then((res) => {
        if (res.status === 1) {
          document.location.href = res.data.redirect_url
        } else {
          setMsgAlert({
            message: Answer(res.message),
            onClose: () => {
              setMsgAlert(undefined)
            }
          })
        }
      })
      .catch(() => {})
      .finally(() => {
        setProcessed(false)
      })
  }

  useEffect(() => {
    if (msgAlert && msgAlert.status === 1) {
      setMsg(undefined)
    }
  }, [msgAlert])

  if (!active) {
    return <></>
  }

  return (
    <Box>
      {!!msg && <ConfirmModal msgs={msg} setMsg={setMsgAlert} />}
      {!!msgAlert && <GlobalAlertModal msg={msgAlert} />}
      {!!msgInfo && <PrizeInfoModal msgs={msgInfo} handleOrder={handleOrderPrize} />}
      <Box>
        <Typography variant="h4" color="secondary">
          ПРИЗЫ
        </Typography>
      </Box>
      {need_ndfl && (
        <Box py={4}>
          <SubmitButton processed={processed} onClick={handlePersona} variant="contained" color="primary">
            Заполнить форму
          </SubmitButton>
        </Box>
      )}

      <Box py={4} px={3} className={classes.slick}>
        {prizs && prizs.length > 0 && (
          <Slider {...settings}>
            {prizs.map((i) => {
              return (
                <Box key={i.id} className={classes.prizItem}>
                  <Box px={2} className="animated zoomIn">
                    <Box py={1} className="name" dangerouslySetInnerHTML={{ __html: russianRules(i.title) as string }} />
                    <Box py={1} className={classes.imgs}>
                      <img src={`/imgs/prizs/${i.key}.png`} alt={i.title} />
                    </Box>
                    <Ball py={1}>{i.score}</Ball>
                    <Box py={1}>
                      <Button variant="outlined" color="primary" fullWidth onClick={() => handleOrder(i)}>
                        Подробнее
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </Slider>
        )}
        {prizs && prizs.length === 0 && (
          <Typography variant="body1" color="secondary">
            Перечень призов обновляется, ожидайте
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default Prize
